
import { Component, Vue, Watch, Prop, Emit } from 'vue-property-decorator';
import { getMenuList } from '@/api/user';
import actions from '@/shared/actions';
import shared from '@/shared';

@Component({})
export default class Topbar extends Vue {
  @Prop({ default: () => {} }) userInfo: any;
  @Prop({
    default: () => ({ name: '', tag: '' })
  })
  curProject!: { name: string; tag: string };
  @Prop({ default: '' }) roleName!: string;
  @Prop({ default: false }) showRouteMenu!: boolean;

  @Watch('curProject.tag')
  projectChange(_val: string) {
    this.getNav();
  }

  // watch路由改变标题
  @Watch('$route.path')
  onPathChange(e: String) {
    this.setActiveMenu(e);
  }

  @Emit('showApps')
  showApps() {}

  triggerSubappToolbox() {
    const trigger = shared.getToolboxTrigger();
    if (trigger) {
      trigger();
    } else {
      console.error('has toobox but no trigger provided');
    }
  }

  @Emit('logout')
  logout() {}

  @Emit('logoutMiAccount')
  logoutMiAccount() {}

  // roleName = '';

  activeMenuPath: string = ''; // 当前选中的菜单路径，如/main-project-name/task/setting, 选中的activeMenuPath=/task
  activeSubMenuPath: string = ''; //当前选中的菜单的子菜单路径，如/main-project-name/task/setting, 选中的activeSubMenuPath=/task/setting
  projectPath: string = ''; // url中project部分的path，值为 main-${project-name}

  showUser: boolean = false;

  unsubscribe: any;

  routeList: any[] = []; // 子应用的菜单列表

  created() {}

  mounted() {
    this.setActiveMenu(this.$route.path);
    this.unsubscribe = shared.subscribe((store) => {});
  }

  beforeDestroy() {
    this.unsubscribe();
    actions.offGlobalStateChange();
  }

  setActiveMenu(path: String) {
    if (path !== '/') {
      const mainPath = path.split('?')[0];
      this.activeSubMenuPath = `/${mainPath.split('/').slice(2, 4).join('/')}`;
      this.activeMenuPath = `/${mainPath.split('/')[2]}`;
      this.projectPath = path.split('/')[1];
    }
  }
  // 过滤不显示菜单
  filterMenu(menus) {
    const _ = (menus) => {
      return menus.filter((item) => {
        if (item?.children?.length) {
          item.children = _(item.children);
        }
        return item.isShow;
      });
    };
    return _(menus);
  }
  // 获取导航列表
  async getNav() {
    this.routeList = [];
    const res: any = await getMenuList(this.curProject.tag);
    if (res.data?.length) {
      res.data.map((item) => (item.arrow = 'down'));
      this.routeList = this.filterMenu(res.data);
    }
  }

  // 处理名字过长
  handleName(str: string) {
    if (!str) return;
    return str.length > 6 ? `${str.slice(0, 6)}...` : str;
  }
  changePath(key: string, i: number) {
    const popover: any = this.$refs.popover;

    // popover下会有多个一级菜单，遍历调用每一个的close
    popover?.forEach((element) => {
      element.doClose();
    });

    this.routeList[i].arrow = 'up';
    this.activeSubMenuPath = key;
    if (key === '/') return;
    this.activeSubMenuPath = key;
    const subRouter = shared.getSubappRouter();
    if (subRouter) {
      subRouter.push({ path: key });
    } else {
      this.$router.push({
        path: `/${this.projectPath}${key}`
      });
    }
  }
  changeArrow(index: number) {
    const value = this.routeList[index].arrow;
    this.routeList[index].arrow = value === 'down' ? 'up' : 'down';
  }
}
