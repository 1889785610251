
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { apiGetUserInfo } from '@/api/user';
import actions from '@/shared/actions';
import clickoutside from '@/directives/clickoutside/clickoutside';
import { getLoginUrl } from '@/utils/helper';
import topMenu from './topMenu.vue';
import Setting from './setting.vue';
import shared from '@/shared';
import { setOnetrackUser } from '@/utils/track';

interface SubProjectModal {
  projectId?: number;
  projectName?: string;
  projectTag: string;
  projectDesc?: string;
  icon?: string;
  url?: string;
  showType?: number;
  key?: string;
  title?: string;
  path?: string;
  [propName: string]: any;
}

interface ProjectItemModal {
  categoryId: number;
  name: String;
  data: Array<SubProjectModal>;
}

@Component({
  components: {
    topMenu,
    Setting
  },
  directives: {
    clickoutside
  }
})
export default class Topbar extends Vue {
  get locationPathName() {
    const { pathname } = location;
    if (pathname === '/') {
      return '/';
    }
    const result = pathname.split('/');
    return result[1];
  }
  @Prop({ default: true }) isHome!: Boolean; // 是否是home页
  // watch路由改变标题
  @Watch('$route.path')
  onPathChange(e: String) {
    this.setTitleInMenus(e);
  }

  // 监听子应用列表
  @Watch('originMenus')
  originMenusChange(val: []) {
    if (!val || !val.length) {
      return false;
    }
    this.setTitleInMenus((this as any).$route.path);
  }

  @Watch('searchTitle', { immediate: true })
  getShowMenus(newValue: string) {
    // eslint-disable-next-line eqeqeq
    if (newValue == '') {
      this.showMenus = this.originMenus;
    } else {
      this.showMenus = this.originMenus.filter((item: SubProjectModal) => {
        return item.projectName && item.projectName.indexOf(newValue) > -1;
      });
    }
  }

  roleName = '';

  userInfo: any = {};

  selectKey: string = '';

  // 显示商品工具
  showGoodsTool: boolean = false;

  isShowMask: boolean = false;

  isShowConfig: boolean = false;

  originMenus: Array<SubProjectModal> = [];

  showMenus: Array<SubProjectModal> = [];

  showRouteMenu: Boolean = false;

  curTitle: string = '';

  searchTitle: string = ''; // title查询值

  env: string = process.env.VUE_APP_ENV;

  unsubscribe: Function = () => {};

  created() {
    this.getUserInfo();
  }

  mounted() {
    actions.setGlobalState({ roleName: '', showGoodsTool: false });
    actions.onGlobalStateChange((state) => {
      console.log('actions onGlobalStateChange', state);
      // ----- 这个位置初始化触发了三次，有待优化
      // state: 变更后的状态; prevState: 变更前的状态
      this.roleName = state.roleName;
      this.originMenus = this.normalizeMenus(state.projects);
      this.showMenus = this.originMenus;
      this.showGoodsTool = state.showGoodsTool;
    }, true);

    this.unsubscribe = shared.subscribe((store) => {
      this.showRouteMenu = store.showMenus;
    });
  }

  beforeDestroy() {
    this.unsubscribe();
    actions.offGlobalStateChange();
  }

  setTitleInMenus(path: String) {
    if (path !== '/') {
      path = `/${path.split('/')[1]}`;
    }
    this.originMenus.forEach((item: SubProjectModal) => {
      if (`/main-${item.projectTag}` !== path) {
        return false;
      }

      this.curTitle = item.projectName || '';
      this.selectKey = item.projectTag || '';
    });
  }

  normalizeMenus(projects: Array<ProjectItemModal> = []): Array<SubProjectModal> {
    return [
      {
        projectName: '首页',
        projectDesc: '主应用',
        key: 'home',
        path: '/',
        projectTag: '/'
      },
      ...projects.reduce(
        (total: SubProjectModal[], item: ProjectItemModal) => total.concat(item.data),
        []
      )
    ];
  }

  // 获取用户信息  项目列表
  async getUserInfo() {
    const res: any = await apiGetUserInfo();
    if (res.code === 0) {
      const userInfo = res.data;
      this.userInfo = res.data;
      setOnetrackUser(userInfo);

      // 用户信息存入setGlobalState供子项目使用
      actions.setGlobalState({ userInfo });
    } else {
      const redirect = encodeURI(window.location.href);
      setTimeout(() => {
        this.$router.push({
          name: 'login',
          query: { redirect }
        });
      }, 1000);
      return false;
    }
  }
  getPath(url) {
    if (url === '/') {
      return url;
    }
    const index = url.lastIndexOf('/');
    return url.substring(index + 1);
  }

  gotoSubApp(projectTag: string) {
    this.closeMenu();

    setTimeout(() => {
      window.location.href = projectTag === '/' ? '/' : `/main-${projectTag}`;
    }, 200);
  }

  showMenu() {
    this.isShowMask = true;
  }

  closeMenu() {
    this.isShowMask = false;
  }

  showConfig() {
    this.isShowConfig = true;
  }

  hideConfig() {
    this.isShowConfig = false;
  }

  logout() {
    const baseUrl = process.env.VUE_APP_BASE_URL;
    const loginUrl = getLoginUrl();
    window.location.href = `${baseUrl}/nr-qiankun-center/sso/passport/logout?followup=${encodeURI(
      loginUrl
    )}`;
  }
  logoutMiAccount() {
    window.open('https://id.mi.com', '_blank');
  }
}
